.sign_intext {
  color: #000;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0;
}
.register__footer {
  display: flex;
}
.sigin_in_p {
  color: #000;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  max-width: 24.75rem;
  line-height: normal;
  margin: 20px 0;
}

.register_text {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px 0;
  a {
    color: rgb(51 65 85);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.sign_in_rigth {
  color: #000;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input_bg {
  border-radius: 0.5rem;
  background: white;
  color: rgb(10, 10, 10);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  border: none;
  padding: 16px 24px;
  border: 1px solid rgb(51 65 85);
  outline: none;
}
.forget_password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  a {
    color: #b0b0b0;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.btn_login {
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  padding: 16px;
  line-height: normal;
  border-radius: 0.5625rem;
  background-color: rgb(51 65 85) !important;
  box-shadow: 0px 4px 61px 0px rgba(77, 71, 195, 0.4);
}
.eye__password {
  position: relative;
}
.eye__password svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(51 65 85);
  width: 20px;
  height: 20px;
}

.or_countinue {
  color: #b5b5b5;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin: 20px 0;
}
