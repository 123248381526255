@use "./varaibles/varaible" as *;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
}
body {
  font-family: $default-font-family !important;
}
span {
  font-family: $default-font-family !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $default-font-family !important;
}
p {
  font-family: $default-font-family !important;
}
em {
  font-family: $default-font-family !important;
}
div {
  font-family: $default-font-family !important;
}
strong {
  font-family: $default-font-family !important;
}

ul li span {
  font-family: $default-font-family !important;
}

ul,
li,
ol {
  font-family: $default-font-family !important;
}
table {
  font-family: $default-font-family !important;
}

a {
  font-family: $default-font-family !important;
}

button {
  font-family: $default-font-family !important;
}

input {
  font-family: $default-font-family !important;
}

textarea {
  font-family: $default-font-family !important;
}

.error {
  color: red;
  font-family: $default-font-family;
  font-size: 14px;
}

a {
  text-decoration: none !important;
}
p {
  margin: 0 !important;
}

.draggable {
  position: fixed;
  top: 10px;
  left: 10px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #dedede;
  z-index: 999;
  background-color: white;
}

.draggable-panel {
  padding: 10px;
  background-color: #eee;
  cursor: move;
}

.draggable-content {
  padding: 15px;
}

.quiz_questions {
  font-family: $default-font-family !important;
  font-size: 18px;
  span {
    font-family: $default-font-family !important;
    font-size: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  p {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  em {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  div {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  strong {
    font-family: $default-font-family !important;
    font-size: 18px;
  }

  ul li span {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  h4 li span {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  span {
    span {
      font-family: $default-font-family !important;
      font-size: 18px;
    }
  }
  span h4 {
    font-family: $default-font-family !important;
    font-size: 18px;
  }

  ul,
  li,
  ol {
    font-family: $default-font-family !important;
    font-size: 18px !important;
    list-style: disc !important;
    padding: 5px 22px !important;
  }
  table {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
}

.options_variant,
.quiz_questions {
  font-family: $default-font-family !important;
  font-size: 18px !important;

  span {
    font-family: $default-font-family !important;
    font-size: 18px;
    span {
      font-family: $default-font-family !important;
      font-size: 18px !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  p {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  em {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  div {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  strong {
    font-family: $default-font-family !important;
    font-size: 18px;
  }

  ul li span {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  h4 li span {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  span {
    span {
      font-family: $default-font-family !important;
      font-size: 18px;
    }
  }
  span h4 {
    font-family: $default-font-family !important;
    font-size: 18px;
  }

  ul,
  li,
  ol {
    font-family: $default-font-family !important;
    font-size: 18px;
    list-style: disc !important;
    padding: 5px 22px !important;
  }
  table {
    font-family: $default-font-family !important;
    font-size: 18px;
  }
  p,
  span {
    font-family: $default-font-family !important;
    font-size: 16px;
    span {
      font-family: $default-font-family !important;
      font-size: 16px;
      span {
        font-family: $default-font-family !important;
        font-size: 16px;
      }
    }
  }
}
.popup {
  background-color: rgb(255, 226, 34) !important;
  font-size: 18px !important;
  font-family: $default-font-family !important;
  font-weight: 200 !important;
  text-decoration: none !important;
  color: black !important;
  .popupItem {
    font-size: 16px !important;
    font-family: $default-font-family !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    color: black !important;
  }
}

.answer_test {
  font-family: $default-font-family !important;
  font-size: 18px;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.app__editable {
  border: 1px solid black;
  border-width: 0px;
  font-size: 20px;
  width: 80%;
  outline: none;
}

.highlighted-text {
  background-color: yellow;
  cursor: pointer;
}
.offcanvas {
  background-color: rgb(11, 25, 40) !important;
  color: white !important;
}
